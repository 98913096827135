.homeMain .hero-section {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 100px;

}

.homeMain .hero-section img {
    width: 100%;
    max-height: 100%;
}

.homeMain .categories{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 5px;
    width:100%;

}
.homeMain .categories .cate{
    width:100%;
    aspect-ratio: 1/1;
    cursor: pointer;
}
.homeMain .categories .cate img{
    width: 100%;
    height: 100%;
}
.trending-collections{
    margin-top: 50px;
}
.trending-collections .trending-collection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px 10px;
}
.trending-collections .trending-collection .trending-card{
    width: 100%;
}
.trending-collections .trending-collection .trending-card a{
    width: 100%;
    height: 100%;
}
.trending-collections .trending-collection .trending-card img{
    width: 100%;
    height: 100%;
}
.shoes-section {
    margin: 30px 0;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    padding: 10px 0;

}

.shoes-section .shoe-left {
    max-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shoes-section .shoe-left img {
    max-height: 50vh;
    max-width:100%;
}

.shoes-section .shoe-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    max-height: 70vh;
    padding: 0 50px;
}

.shoes-section .shoe-right p:nth-child(1) {
    font-size: 35px;
    margin-bottom: 20px;
    font-family: var(--heading-font);
    letter-spacing: 0px;
    line-height: 1.2;
    color: var(--heading-color);
    /* text-align: center; */
}

.shoes-section .shoe-right p:nth-child(2) {

    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: var(--heading-font);
    font-size: 18px;
    /* text-align: center; */
    color: var(--secondary-color);
}

.shoes-section .shoe-right a {
    /* text-align: left; */
    padding: 7px 0;
    background-color: none;
    border: 3px solid var(--heading-color);
    text-align: center;
    color: white;
    background-color: var(--heading-color);
    font-size: 20px;
    font-weight: 600;
    width: 200px;
    transition: all 0.3s;
}
.shoes-section .shoe-right a:hover{
    background-color: transparent;
    color: black;
}

.icon-footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
}
.icon-footer div{
    text-align: center;
    background-color: white;
    padding: 10px 0;
    text-transform: uppercase;
}
.icon-footer img{
    width: 100px;
    height: 100px;
}
@media screen and (max-width:1200px) {
    /* .homeMain .hero-section img {
        width: 170%;
        min-height:70%;
        max-height: 80%;
    } */
}
@media screen and (max-width:950px){
    .homeMain .hero-section{
        margin-top: 100px;
    }
    
}
@media screen and (max-width:650px) {
    .homeMain .hero-section {
        margin-top: 100px;
    }
    .trending-collections .trending-collection{
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
    .homeMain .categories{
        grid-template-columns: 1fr;
    }
    .homeMain .category-devide {
        width: 100vw;
        flex-direction: column;
    }
.homeMain .categories{
    margin-top: 10px;
}

    .homeMain .category-devide div:hover {
        width: 90%;
        transition: all 0.3s;
    }

    .homeMain .category-devide div {
        width: 100%;
    }

    .shoes-section {

        grid-template-columns: 1fr;
        height: 100%;

    }

    .shoes-section .shoe-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 20px 10px;
    }
    .shoes-section .shoe-right p:nth-child(1) {
        font-size: 25px;
        margin-bottom: 5px;

    }
    
    .shoes-section .shoe-right p:nth-child(2) {
    
        font-size: 14px;
    }
    .shoes-section .shoe-right a{
        width: 150px;
        font-size: 13px;
    }
    .icon-footer{
        display: grid;
        /* grid-template-columns: 1fr; */
        grid-gap: 5px;
        text-align: center;
        font-size: 11px;
    }
    .icon-footer img{
        width: 50px;
        height: 50px;
    }
}