.Products{
    max-width: 100vw;
    margin: 110px 10px 20px 10px;
}
.Products .loading{
    position: fixed;
    top: 75px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 3;
}
.Products .loading .loader{
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 30vh;
    text-align: center;
}
.Products .loading .loader img{
    width: 100px;
    aspect-ratio: 1/1;
}
.Products .loading .loader p{
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 10px;
}
.Products .p-headi{
    margin: 10px 0;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
}
.Products .filter{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 10px;
    margin: 10px 0;
    background-color: white;
}
.Products .filter .option{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;   
    margin-bottom: 0; 
}
.Products .filter .option span{
    margin-left: 10px;
}
.Products .filter .option:nth-child(2){
    justify-content: flex-end;
}
.Products .filter .filter-box{
    position: fixed;
    z-index: 12;
    background-color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    font-family: var(--primary-font);
    min-height: 20vh;
    transition: all 0.3s ease-in-out;
}
.Products .filter .filter-box .headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-bottom: 1px solid rgb(112, 112, 112);
}
.Products .filter .filter-box .filter-body{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    margin-top: 1px;
    min-height: 20vh;
}
.Products .filter .filter-box .filter-body .left{
    background-color: rgb(244, 244, 244);
} 
.Products .filter .filter-box .filter-body .left ul{
    padding: 0;
}
.Products .filter .filter-box .filter-body .left ul li{
padding: 15px 10px;
font-weight: 400;
border-bottom: 1px solid rgb(193, 193, 193);
cursor: pointer;
}
.Products .filter .filter-box .filter-body .left ul .selected{
    background-color: white;
    border-left: 3px solid black;
}
.Products .filter .filter-box .filter-body .right .content{
    margin: 10px 0;
}
.Products .filter .filter-box .filter-body .right .content p{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}
.Products .filter .filter-box .filter-body .right .content ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
}
.Products .filter .filter-box .filter-body .right .content ul li{
    border: 1px solid rgb(185, 185, 185);
    color: rgb(185, 185, 185);
    padding: 0px 6px;
    font-weight: 500;
    border-radius: 15px;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 12px;
    cursor: pointer;
}
.Products .filter .filter-box .filter-body .right .content ul li.selected{
    border: 1px solid black;
    color: black;
}
.Products .filter .filter-box .filter-foot{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-top: 1px solid rgb(112, 112, 112);
}
.Products .filter .filter-box .filter-foot .count{
    font-size: 15px;
}
.Products .filter .filter-box .filter-foot .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Products .filter .filter-box .filter-foot .right button{
    margin-left: 10px;
    padding: 10px 5px;
    border-radius: 5px;
    width: 100px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
}
.Products .filter .filter-box .filter-foot .right button:nth-child(1){
    border: 1px solid black;
    color: black;
    background-color: transparent;
}
.Products .filter .filter-box .filter-foot .right button:nth-child(2){
    border: 1px solid black;
    color: white;
    background-color: black;
}
.Products .filter .sort-box{
    position: fixed;
    z-index: 12;
    background-color: white;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    font-family: var(--heading-font);
    min-height: 40vh;
    transition: all 0.3s ease;
}
.Products .filter .sort-box .headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-bottom: 1px solid rgb(112, 112, 112);
}
.Products .filter .sort-box .sort-body{
    margin-top: 1px;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Products .filter .sort-box .sort-body p{
    border: 1px solid rgb(185, 185, 185);
    color: rgb(185, 185, 185);
    padding: 10px 10px;
    font-weight: 500;
    border-radius: 15px;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 15px;
    cursor: pointer;
    margin: 10px 15px;
}
.Products .filter .sort-box .sort-body p.selected{
    border: 1px solid black;
    color: black;
}
.Products .products-box .products-page .pages{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.Products .products-box .products-page .pages button .b-icon{
    font-size: 30px;
}
.Products .products-box .products-page .pages p{
    background-color: rgb(35, 35, 35);
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    font-weight: 600;

}
@media screen and (max-width:1250px) {
    .Products .products-listing .product-list{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (max-width:950px) {
    .Products .products-listing .product-list{
        grid-template-columns:1fr 1fr;
        grid-gap: 3px;
    }
}
@media screen and (max-width:650px){
    .Products{
        margin: 110px 5px 10px 5px;
    }
        .Products .sorting{
            background-color: white;
            padding: 10px;
        }
        .Products .hr{
            display: none;
        }
        .Products .p-headi{
            font-size: 25px;
            margin: 10px 0;
        }
}


