.contact{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    flex-direction: column;
}
.contact .contacts-page{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    
}
.contact .map{
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
.contact .contacts-page .contact-left{
    display: grid;
    grid-template-columns: 2fr 1fr;
    background-color: white;
    padding: 50px;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.contact .contacts-page .contact-left p:nth-child(1){
    color: rgb(29, 29, 29);
    font-size: 35px;
    font-family: var(--heading-font);
    letter-spacing: 1px;
    margin-bottom: 0px;
}
.contact .contacts-page .contact-left p:nth-child(2){
    color: rgb(158, 158, 158);
    font-size: 12px;
    font-family: var(--heading-font);
    margin-bottom: 10px;
    font-weight: 600;
}

.contact .contacts-page .contact-left form{
    margin-top: 40px;
}
.contact .contacts-page .contact-left form input{
    margin-bottom: 20px;
    width: 100%;
    padding: 9px 10px;
    border-radius: 10px;
    background-color: rgb(244, 244, 244);
    border: none;
    outline: none;
}
.contact .contacts-page .contact-left form input::placeholder{
    font-size: 13px;
    color: rgb(199, 199, 199);
}
.contact .contacts-page .contact-left form textarea::placeholder{
    font-size: 13px;
    color: rgb(199, 199, 199);
}
.contact .contacts-page .contact-left form textarea{
    margin-bottom: 20px;
    width: 100%;
    padding: 9px 10px;
    border-radius: 10px;
    background-color:rgb(244, 244, 244);
    border: none;
    outline: none;
    height: 150px;
}
.contact .contacts-page .contact-left form .submit-btn{
    width: 100px;
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    background-color: black;
    padding: 5px 8px;
}
.contact .contacts-page .contact-left form .call{
    width: 100px;
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    background-color: black;
    padding: 8px 8px;
    margin-left: 15px;
}
.contact .contacts-page .contact-right{
    position: absolute;
    right: 0;
    width: 370px;
    height: 430px;
    background-color: black;
    color: white;
    padding: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.contact .contacts-page .contact-right p:nth-child(1){
    font-size: 30px;
    margin-bottom: 20px;
}
.contact .contacts-page .contact-right div{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.contact .contacts-page .contact-right div p{
    margin-left: 10px;
    margin-bottom: 0;
}

@media screen and (max-width:950px) {
    .contact .contacts-page{
        width: 100%;
        display: block;
        
    }
    .contact .contacts-page .contact-left{
        grid-template-columns:1fr;
        padding: 50px;
        width: 100%;
    }
    .contact .contacts-page .contact-right{
        position: relative;
        margin-top: 20px;
        width:100%;
        padding: 50px;
    }
}
@media screen and (max-width:650px){
    .contact .contacts-page .contact-left{
        padding: 30px 20px;
    }
    .contact .contacts-page .contact-right{
        padding: 30px 20px;

    }
}

@media screen and (min-width:1450px) {
    .contact .contacts-page .contact-right{
        width: 450px;
    }
}