.checkout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    padding: 0 20px;
    margin-top:115px;
}
.coupons{
    padding:5px;
    background-color: rgb(255, 249, 238);
    color: orange;
    border: 2px solid orange;
    margin-bottom: 15px !important;
    
}

.coupons p .ico{
    font-size: 15px;
}
.coupons p{
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0;
}
.coupons p span{
    margin-right: 5px;
}
.checkout .left-form form select{
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    background-color: white;
}
.checkout .phone-veri{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.checkout .phone-veri input{
    width: 30%;
    padding: 15px 10px;
    margin-right: 10px;


}
.checkout .phone-veri p{
    margin: 0 10px;
}
.v-tag{
    /* background-color: rgba(156, 255, 156, 0.473); */
    color: green;
    border-radius: 15px;
}
.checkout .phone-veri button{
    padding: 8px 15px;
    border: none;
    background-color: black;
    color: white;
    font-weight: 500;

}
.checkout div{
    width: 100%;
    margin-bottom: 5px;
}
.checkout .left-form{
    padding-right: 20px;
    margin: 50px 0;
}

.checkout div{
    width: 100%;
}
.checkout div input{
    width: 100%;
}
.checkout .input{
    margin-bottom: 10px;
}
.checkout select{
    padding: 16px 0;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgb(180, 180, 180);
    background-color: white;
    border-radius: 5px;
}
.checkout .name{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.checkout .city{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
}
.checkout .razorpay{
    border: 1px solid rgb(233, 233, 233);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout .razorpay img{
    height: 30px;
    background-blend-mode: multiply;
}
.checkout .razorpay span{
    font-weight: 600;
    font-family: var(--paragraph-font);
}
.checkout .pay{
    margin-top: 20px;
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    background-color:black;
    letter-spacing: 1px;
    border: none;
}
.checkout .pay:active{
    scale: 1.01;
}
.pay-details{
    padding: 10px 0;
}
.pay-details p{
    display: flex;
    align-items: center;
}
.pay-details p span:nth-child(1){
    font-weight: 500;
    margin-right: 5px;
}
.paynow button{
    padding: 5px 20px;
    border: 2px solid black;
    color: white;
    background-color: black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    transition: all 0.3s;
}
.paynow button:hover{
    background-color: transparent;
    color: black;
}
.checkout .right-form{
    border-left: 1px solid grey;
    padding: 50px 30px;

}
.checkout .right-form .prdt{
    display: grid;
    grid-template-columns: 3fr 1fr 0.3fr;
    justify-content: center;
    height: 100px;
    margin-bottom: 10px;
}
.checkout .right-form .prdt .detail{
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 10px;
    justify-content: center;
}
.checkout .right-form .prdt .detail img{
    width: 80px;
    aspect-ratio: 1/1;
    border: 1px solid grey;
}
.checkout .right-form .p-price{
    text-align: right;
    font-weight: 500;
}
.checkout .right-form .p-name{
    font-weight: 500;
    font-size: 14px;
}
.checkout .right-form .size{
    font-weight: 400;
    font-size: 11px;
    margin-top: 2px;
}
.checkout .right-form p{
    margin-bottom: 0;
}
.checkout .right-form .delete{
    margin-left: 10px;

}
.checkout .right-form .delete .delete-icon{
    color: gray;
    font-size: 17px;
    cursor: pointer;
}
.checkout .right-form .coupon{
    display: grid;
    grid-template-columns: 4fr 1fr;
    height: 50px;
    grid-gap: 10px;
}
.checkout .right-form .coupon input{
    border: 1px solid rgb(189, 189, 189);
}
.checkout .right-form .coupon button{
    border: none;
    background-color:black;
    color: white;
    font-weight: 500;
}
.checkout .right-form .total{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
.checkout .right-form .total ul{
    padding-left: 0;
}
.checkout .right-form .total ul li{
    margin-bottom: 10px;

}
.checkout .right-form .total ul:nth-child(2){
    text-align: right;
    font-weight: 500;
}
.checkout .pament{
    margin: 10px 0 20px 0;
    padding: 0 5px;
}

@media screen and (max-width:950px) {
    .checkout{
        grid-template-columns: 1fr;
    }
    .checkout .left-form{
        padding:0 20px;
    }
    .checkout .right-form{
        border-left: none;
        padding: 50px 20px;
    
    }
    .checkout .banking .qr img{
        margin: auto;
        width:250px;
        aspect-ratio: 1/1;
    }

}
@media screen and (max-width:650px){
    .checkout{
        padding: 0 10px;
    }
    .checkout .left-form{
        padding:0;
        margin: 20px 0;
    }
    .checkout .right-form{
        padding:0;
        margin: 50px 0 10px 0;
    }
    .checkout .left-form form div{
        display: block;
    }
    .checkout .left-form form div input{
        margin-bottom: 0px;
    }
    .checkout .left-form form div select{
        margin-bottom: 5px;
        padding: 13px 0;
        border-radius: 5px;
    }
    .checkout form .razorpay .up{
        display: block;
        padding: 20px 0;
        border: 1px solid rgb(233, 233, 233);
    }
    .checkout form .razorpay .up img{
        display: none;
    }
    .checkout form .razorpay .bottom{
        padding: 20px 10px;
    }
}
