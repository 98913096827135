.orders-page{
    margin: 110px 10px 20px 10px;
    background-color: white;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--primary-font);
    min-height: 50vh;
}
.orders-page .orders-head{
    text-align: center;
    font-size: 34px;
    margin-bottom: 20px;
    font-weight: 700;
}
.orders-page .email-field{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}
.orders-page .email-field input{
    width: 250px;
    padding: 10px;
    outline: none;
    border: 1px solid rgb(118, 118, 118);
}
.orders-page .email-field button{
    background-color: black;
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
    padding: 11px 20px;
    border: none;
    margin-left: 10px;
}

.orders-page .no-data{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.orders-page .no-data img{
    width: 250px;
    aspect-ratio: 1/1;
}
.orders-page .no-data p:nth-child(2){
font-size: 25px;
font-weight: 500;
}
.orders-page .no-data p:nth-child(3){
    font-size: 13px;
}

.orders-page .no-data button{
    margin-top: 10px;
    background-color: black;
    color: white;
    font-size: 15px;
    border: none;
    letter-spacing: 1px;
    padding: 10px 20px;
}

.orders-page .all-orders .order{
    margin-bottom: 30px;
    border: 1px solid var(--border-color);
    padding: 10px;
    font-family: var(--primary-font);
}
.orders-page .all-orders .order .number{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}
.orders-page .all-orders .order .items .item{
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color);
}
.orders-page .all-orders .order .items .item img{
    width: 120px;
    height: 120px;
    margin-right: 10px;
    border: 1px solid var(--border-color);
    padding: 2px;
}
.orders-page .all-orders .order .items .item .right{
color: var(--secondary-color);
margin-top: -2px;
}
.orders-page .all-orders .order .items .item .right p{
    margin-bottom: 3px;
    color: rgb(179, 179, 179);
    /* font-weight: 600; */
    font-size: 13px;
}
.orders-page .all-orders .order .items .item .right p span{
    margin-right: 8px;
    font-weight:600;
}
.orders-page .all-orders .order .items .item .right .name{
    color: var(--secondary-color);
    font-size: 17px;
}
.orders-page .all-orders .order .info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.orders-page .all-orders .order .info ul{
    padding: 0;
    color: var(--secondary-color);
}
.orders-page .all-orders .order .info ul:nth-child(2){
    text-align: right;
}
.orders-page .all-orders .order .info ul li{
    margin-bottom: 5px;
}
.orders-page .all-orders .order .info ul li:last-child{
    font-weight: 600;
    font-size: 18px;
}

@media screen and (max-width:650px) {
    .orders-page .orders-head{
        font-size: 28px;
        font-weight: 600;
    }
    .orders-page{
        margin: 110px 5px 10px 5px;
        padding: 20px 5px;
    }
}

