.main-head{
    background-color: black;
    font-family: var(--paragraph-font);
    height: 50px;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    }

.main-head .center{
    margin: 0 50px;
    display: flex;
    align-items: center;
}
.main-head .center .days{
margin: 0 10px;
text-align: center;
}
.main-head .center .days:nth-child(1){
margin-right: 20px;
}
.main-head .center .colon{
color: white;
font-weight: 800;
font-size: 26px;
}
.main-head .center .days p:nth-child(1){
color: white;
font-size: 27px;
font-weight: 900;
letter-spacing: 3px;
margin-bottom: 0;
line-height: 1;
margin-bottom: 1px;
}
.main-head .center .days p:nth-child(2){
color: rgb(232, 232, 232);
font-size: 13px;
font-weight: 500;
line-height: 1;

}
.main-head .left p{
    color: #FFE100;
    font-weight: 700;
    font-size: 28px;
}
.main-head .right p{
    color: rgb(224, 224, 224);
    font-weight: 500;
    font-size: 17px;
}
.main-head p{
    margin-bottom: 0;
}
    
    .overlay {
        display: none;
      }
    
    
    .header{
        position:fixed;
        top: 50px;
        width: 100vw;
        padding: 5px 20px;
        font-family: 'Montserrat', sans-serif;
        z-index: 4;
    
    
    }
    .header .cart-len{
        position: relative;
    }
    .header .headerdiv .cart-length{
    position: absolute;
    top: 6px;
    right: 4px;
    background-color: var(--secondary-color);
    border: 1px solid white;
    color: white;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    border-radius: 50%;
    
    
    }
    .header .headerdiv1 .cart-length{
        position: absolute;
        top: 0px;
        right: 15px;
        background-color: var(--secondary-color);
        border: 1px solid white;
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        border-radius: 50%;
        z-index: 3;
        
        
        }
    .search{
        margin: 10px auto;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .search input{
        padding: 10px 20px;
        width: 100%;
        border-radius: 10px;
        border: none;
        background-color: rgba(225, 225, 225, 0.758);
        border-color: transparent;
    }
    .header{
        background-color: white;
    }
    
    .headerdiv{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr 2fr 1fr;
    
    }
    .headerdiv .head2 ul{
        list-style: none;
        display: flex;
        margin-bottom: 0;
        padding: 0;
        justify-content: center;
    }
    .headerdiv .head2 ul li{
        margin: 0 12px;
        position: relative;
        display: flex;
        align-items: center;
    }
    .headerdiv .head2 ul li a{
        color: var(--heading-color);
        transition: all 0.5s;
        display: flex;
        align-items: center;
    }
    
    
    .head3 ul{
        list-style: none;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .headerdiv .head3 ul li{
        margin: 0 10px;
        cursor: pointer;
        color: black;
        font-weight: 400;
        font-size: 27px;
    
    }
    .head3 .li-search{
        display: flex;
        align-items: center;
        border-bottom: 2px solid black;
    }
    .header .mob-s,.header .cart-i{
        font-size: 38px;
        margin-right: 10px;
    
    }
    .head3 .li-search .s-icon{
    color: rgb(52, 52, 52);
    font-size: 30px;
    }
    .head3 .li-search input{
        border:none;
        outline: none;
        background-color: transparent;
    width: 120px;    
    }
    .head3 .li-search input::placeholder{
        font-size: 10px;
        transition: all 0.3s;
        color: black;
    }
    .head3 ul li a{
        color: black;
    }
    
    .headerdiv .head3 ul li a:hover{
        box-shadow: none;
    
    }
    .headerdiv .head1 img{
        width: 160px;
    }
    .headerdiv1{
        display: grid;
        justify-content: center;
        grid-template-columns:0.5fr 2fr 0.5fr;
    }
    .headerdiv1 .head1 img{
        width: 170px;
    }
    .headerdiv1 .head1{
        text-align: center;
    }
    .headerdiv1 .head3 ul li{
        margin-left: 7px;
        cursor: pointer;
        color: black;
        font-weight: 400;
    }
    .hamburger .icon{
        font-size: 25px;
    }
    .header1 .head2{
        top: 0;
        position: absolute;
        left: -100%;
        background-color: rgb(255, 255, 255);
        width: 70vw;
        height: 100vh;
        padding:10px;
        transition: all 0.2s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
    }
    .header1 .head2 ul{
        list-style: none;
        text-align: left;
        padding-top: 50px;
        padding-left: 0;
        width:60vw;
    
    }
    .header1 .head2 ul li{
        margin-bottom: 5vh;
        width: 100%;
    }
    .cancel-icon{
        position: absolute;
        right: 10px;
    }
    .header1 .head2 .head1 img{
        width: 170px;
        text-align: left;
        position: absolute;
        left: 10px;
    }
    .header1 .head2 ul li{
        display: flex;
        align-items: center;
        font-weight: 400;
        width: 100%;
        color: var(--secondary-color);
    }
    .header1 .head2 ul li a{
        margin-left: 10px;
        width: 100%;
        font-weight: 500;
        color: var(--secondary-color);

    }
    .header1{
        display: none;
    }
    
    .error{
        color: red;
        font-size: 10px;
    }
    
    .head3 ul{
        margin-bottom: 0;
        padding: 0;
    }
    @media screen and (max-width:950px) {
        .no-scroll {
            overflow: hidden;
          }
        .overlay {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
            z-index:0; /* Ensure it's above other content */
          }
        .header{
            padding: 10px 5px;
            background-color: white;    
    
        }
        .header1{
            display: block;
        }
        .headerdiv{
            display: none;
        }
        .head3 .li-search input{
            display: none;
        }
        
    
        
    
    
    }
    @media screen and (max-width:650px) {
        .headerdiv1 .head1 img{
            width: 130px;
        }
        .header .mob-s,.header .cart-i{
            font-size: 30px;
            margin-right: 0px;
        }
        .main-head p{
            font-size: 11px;
        }
        .header .headerdiv1 .cart-length{
            top: -2px;
            right: 5px;
            width: 17px;
            height: 17px;
        }



        .main-head .center{
            margin: 0 30px;
        }
        .main-head .center .days{
        margin: 0 5px;
        }
        .main-head .center .days:nth-child(1){
        margin-right: 15px;
        }
        .main-head .center .colon{
        font-weight: 700;
        font-size: 20px;
        }
        .main-head .center .days p:nth-child(1){
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 1px;
        }
        .main-head .center .days p:nth-child(2){
        font-size: 11px;
        }
        .main-head .left p{
            font-size: 19px;
        }
        .main-head .right p{
            font-size: 15px;
        }
    }
    @media screen and (max-width:500px) {
        .main-head{
            justify-content: space-between;
            padding: 0 5px;
        }
        .main-head .center{
            margin: 0 15px;
        }
        .main-head .center .days{
        margin: 0 5px;
        }
        .main-head .center .days:nth-child(1){
        margin-right: 5px;
        }
        .main-head .center .colon{
        font-weight: 700;
        font-size: 15px;
        }
        .main-head .center .days p:nth-child(1){
        font-size: 18px;
        font-weight: 700;
        }
        .main-head .center .days p:nth-child(2){
        font-size: 9px;
        }
        .main-head .left p{
            font-size: 17px;
        }
        .main-head .right p{
            font-size: 13px;
        }
    }