@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
:root{
    --paragraph-font:"Montserrat", serif;
    --heading-font:"Merriweather", serif;
    --bg-color:rgb(216, 216, 216);
    --price-color:#bc6611;
    --primary-color:#EEEEEE;
    --secondary-color:#686D76;
    --heading-color:#373A40;
    --button-color:#DC5F00;
    
}


